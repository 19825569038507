import { Environment } from './model'

export const environment: Environment = {
  baseUrl: 'https://www.domain.com',
  production: true,
  api: {
    url: '/api',
    version: 'v1'
  }
}
